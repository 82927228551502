import React from 'react';
import { Box, BoxProps, styled } from '@mui/material';

export type LayoutBodyProps = BoxProps & {};

const LayoutBody = styled(({ className, ...props }: LayoutBodyProps) => <Box {...props} className={className + ' AppLayoutBody'} />)(({ theme }) => ({
  padding: theme.spacing(2),
}));

export default LayoutBody;
