import React, { FC, useState } from 'react';
import PageTitle from '@components/PageTitle';
import PageTitleRow from '@components/PageTitleRow';
import LayoutBody from '@components/Layout/LayoutBody';
import Grid from '@mui/material/Unstable_Grid2';
import { Alert, Box, BoxProps, Button, LinearProgress, Snackbar, SnackbarCloseReason, TextField, Typography } from '@mui/material';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import PersonIcon from '@mui/icons-material/Person';
import { useUserListQuery } from '@api/user';
import { useDebounce } from '@uidotdev/usehooks';
import { useDownloadVideoMutation } from '@api/transfer_youtube';
import { FetchBaseQueryError } from '@reduxjs/toolkit/src/query/fetchBaseQuery';

const TransferVideo = () => {
  const [value, setValue] = useState('');
  const [valueUrl, setValueUrl] = useState('');
  const [statusRequest, setStatusRequest] = useState('');
  const [open, setOpen] = useState(false);
  const debouncedSearchTerm = useDebounce(value, 1000);

  const { data: userProfile, isFetching: userProfileFetching } = useUserListQuery({ filter: { nickname: debouncedSearchTerm } }, { skip: !value });
  const [downloadVideo, { isLoading, error }] = useDownloadVideoMutation({});

  const handleClick = () => {
    setOpen(true);
  };

  const getUserLabel = () => {
    if (userProfileFetching) return 'Поиск пользователя...';
    if (userProfile?.results[0]) return `Пользователь: ${userProfile?.results[0].profile?.nickname} существует`;
    if (value.length <= 4 && value.length > 1) return 'Длина никнейма не должна быть меньше 5 символов';
    if (value.length === 0) return 'Введите пользователя';
    return 'Такого пользователя не существует';
  };

  const handleClose = (event: React.SyntheticEvent | Event, reason?: SnackbarCloseReason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handlePublish = async () => {
    if (userProfile?.results[0] && value) {
      handleClick();
      await downloadVideo({ youtube_url: valueUrl, user_id: userProfile?.results[0].id })
        .unwrap()
        .then(() => setStatusRequest('Видео поставлено в очередь на загрузку и обработку.'))
        .catch(error => {
          if ('status' in error) {
            const fetchError = error as FetchBaseQueryError;
            if (fetchError.status === 'FETCH_ERROR' || fetchError.status === 'PARSING_ERROR') {
              setStatusRequest(fetchError.error);
            } else {
              setStatusRequest(`Error ${fetchError.status}: ${JSON.stringify(fetchError.data)}`);
            }
          } else if ('message' in error) {
            setStatusRequest(error.message);
          } else {
            setStatusRequest('An unknown error occurred');
          }
        });
    }
  };

  return (
    <>
      <PageTitleRow>
        <PageTitle>Перенос видео</PageTitle>
      </PageTitleRow>
      <LayoutBody>
        <Grid container spacing={0} gap={2}>
          <CustomBox roundedCorner={'10px 10px 0 0'}>
            <Box display="flex" alignItems="center" gap={1}>
              <PlayCircleIcon />
              <Typography>Ссылка на видео</Typography>
            </Box>
            <Box>
              <Typography>Добавьте ссылку на видео, которое необходимо перенести в Tach</Typography>
            </Box>
            <TextField
              value={valueUrl}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setValueUrl(e.currentTarget.value)}
              sx={{ bgcolor: 'white', borderRadius: '10px' }}
              size={'small'}
              id="outlined-basic"
              label="URL"
              variant="outlined"
            />
          </CustomBox>
        </Grid>
        <Grid container spacing={0}>
          <CustomBox roundedCorner={'0 0 10px 10px'}>
            <Box display="flex" alignItems="center" gap={1}>
              <PersonIcon />
              <Typography>Пользователь Tach</Typography>
            </Box>
            <Box>
              <Typography>Выберите пользователя, от кого будет опубликован контент</Typography>
            </Box>
            <TextField
              color={userProfileFetching ? 'warning' : userProfile?.results[0] ? 'success' : 'error'}
              error={!userProfile?.results[0] && !isLoading && userProfile?.results !== undefined}
              sx={{ bgcolor: 'white', borderRadius: '10px' }}
              size="small"
              id="user_id"
              label={getUserLabel()}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setValue(e.currentTarget.value)}
            ></TextField>
            <Box>{userProfileFetching && <LinearProgress color="primary" />}</Box>
          </CustomBox>
        </Grid>
        <Button variant="contained" sx={{ bgcolor: 'secondary', marginTop: '10px' }} disabled={!userProfile?.results[0] || isLoading} onClick={handlePublish}>
          {isLoading ? 'Загрузка...' : 'Опубликовать'}
        </Button>
        {statusRequest && (
          <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
            <Alert severity={statusRequest === 'Видео поставлено в очередь на загрузку и обработку.' ? 'success' : 'error'}>{statusRequest}</Alert>
          </Snackbar>
        )}
      </LayoutBody>
    </>
  );
};

export default TransferVideo;

interface CustomBoxProps extends BoxProps {
  roundedCorner?: string;
}

const CustomBox: FC<CustomBoxProps> = ({ children, roundedCorner = '', ...rest }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        width: 512,
        height: 136,
        padding: 2,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flexStart',
        gap: 1,
        bgcolor: '#E8EAF6',
        borderRadius: roundedCorner,
      }}
      {...rest}
    >
      {children}
    </Box>
  );
};
