import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_HOST } from '@src/config';
import Session from 'supertokens-web-js/recipe/session';

export const paymentsApi = createApi({
  reducerPath: 'paymentsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_HOST}/admin-api/v1`,
    prepareHeaders: async headers => {
      const accessToken = await Session.getAccessToken();
      if (accessToken) {
        headers.set('Authorization', `Bearer ${accessToken}`);
      }
      return headers;
    },
  }),
  endpoints: builder => ({
    getTransferOutList: builder.query({
      query: params => ({
        url: '/payments/transfer_out/list/',
        method: 'GET',
        params,
      }),
    }),
    approveTransferOut: builder.mutation({
      query: toUserId => ({
        url: `/payments/transfer_out/${toUserId}/approve/`,
        method: 'POST',
      }),
    }),
    rejectTransferOut: builder.mutation({
      query: toUserId => ({
        url: `/payments/transfer_out/${toUserId}/reject/`,
        method: 'POST',
      }),
    }),
  }),
});

export const { useGetTransferOutListQuery, useApproveTransferOutMutation, useRejectTransferOutMutation } = paymentsApi;
