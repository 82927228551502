import React, { useEffect, useRef, useState } from 'react';
import { IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Chip } from '@mui/material';
import { PER_PAGE_LIST } from '@src/config';
import CheckIcon from '@mui/icons-material/Check';
import { ComplaintStatus, ComplaintStatusTitle } from '@src/types';

type ColumnsType<T> = {
  tableHeadTitle?: string;
  sortKey?: string;
  headerRender?: () => React.ReactNode;
};

type CommonTableProps<T> = {
  onRowsPerPageChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onPageChange: (event: unknown, newPage: number) => void;
  onSort: (property: string) => void;
  rowsPerPage: number;
  count: number;
  page: number;
  orderBy?: string;
  order?: 'asc' | 'desc';
  data: T[];
  columns: ColumnsType<T>[];
  onSelect?: (item: T) => void;
  rowData: (item: T) => React.ReactNode[];
  onRowClick?: (item: T) => void;
};
const CommonTable = <T,>({
  onRowsPerPageChange,
  onPageChange,
  rowsPerPage,
  count,
  page,
  orderBy,
  order,
  data,
  onSort,
  onSelect,
  columns,
  rowData,
  onRowClick,
}: CommonTableProps<T>) => {
  const tableRef = useRef<HTMLDivElement | null>(null);
  const [inPopover, setInPopover] = useState<boolean>(false);

  useEffect(() => {
    if (tableRef.current && !!tableRef.current.closest('.MuiPopover-root')) {
      setInPopover(true);
    }
  }, [tableRef.current]);

  return (
    <>
      <TableContainer ref={tableRef}>
        <Table sx={{ minWidth: 500 }} stickyHeader>
          <TableHead>
            <TableRow>
              {columns.map(({ tableHeadTitle, sortKey, headerRender }) => (
                <TableCell key={String(sortKey)} sortDirection={sortKey ? (orderBy === sortKey ? order : false) : false}>
                  {headerRender ? (
                    headerRender()
                  ) : sortKey ? (
                    <TableSortLabel
                      onClick={() => onSort(String(sortKey))}
                      direction={orderBy === String(sortKey) ? order : 'desc'}
                      active={orderBy === String(sortKey)}
                    >
                      {tableHeadTitle}
                    </TableSortLabel>
                  ) : (
                    tableHeadTitle
                  )}
                </TableCell>
              ))}
              {onSelect && <TableCell />}
            </TableRow>
          </TableHead>

          <TableBody>
            {data?.map((item: any) => (
              <TableRow key={String(item?.id)} sx={{ cursor: 'pointer' }}>
                {rowData(item).map((cell, index) => {
                  const shortId = item.id ? item.id.split('-')[0] : item.object_id.split('-')[0] || 'N/A'; // Use 'N/A' if both are missing
                  if (index === 0) {
                    return (
                      <TableCell onClick={() => onRowClick?.(item)} key={index} sx={{ fontWeight: 'bold' }}>
                        {shortId}
                      </TableCell>
                    );
                  }
                  return <TableCell key={index}>{cell}</TableCell>;
                })}

                {onSelect && (
                  <TableCell>
                    <IconButton
                      sx={{ color: 'success.main' }}
                      onClick={e => {
                        e.stopPropagation();
                        onSelect(item);
                      }}
                    >
                      <CheckIcon />
                    </IconButton>
                  </TableCell>
                )}
                {item.contentDisplay && (
                  <TableCell>
                    <Chip
                      sx={{
                        border: 'none',
                        backgroundColor: item.status === ComplaintStatus.NEW ? '#E8EAF6' : item.status === ComplaintStatus.REJECTED ? '#C5E1A5' : '#EF9A9A',
                        color: 'black',
                      }}
                      size="small"
                      label={ComplaintStatusTitle[item.status as keyof typeof ComplaintStatusTitle]}
                    />
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={PER_PAGE_LIST}
        onRowsPerPageChange={onRowsPerPageChange}
        onPageChange={onPageChange}
        rowsPerPage={rowsPerPage}
        count={count}
        page={page}
        component="div"
      />
    </>
  );
};

export default CommonTable;
