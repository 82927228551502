import { UserProfile } from '@src/types/models';
import { ComplaintStatus, ExtendedUserContentType } from '@src/types';

export function debug(...args: any[]) {
  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line
    console.log(...args);
  }
}

export function debounce(delay: number, fn: Function) {
  let timer: NodeJS.Timeout;
  return function (...args: []) {
    timer && clearTimeout(timer);
    timer = setTimeout(() => fn(...args), delay);
  };
}

export function getUserName(profile: UserProfile | undefined | null): string {
  if (profile?.firstname || profile?.lastname) {
    return `${profile.firstname} ${profile.lastname}`;
  }
  return 'Anonymous';
}

export const getStatusText = (status: ComplaintStatus) => {
  switch (status) {
    case ComplaintStatus.NEW:
      return 'Новая жалоба';
    case ComplaintStatus.ACCEPTED:
      return 'Жалоба принята';
    case ComplaintStatus.REJECTED:
      return 'Жалоба отклонена';
    default:
      return 'Неизвестный статус';
  }
};

export const getChipColor = (status: ComplaintStatus) => {
  switch (status) {
    case ComplaintStatus.NEW:
      return 'success';
    case ComplaintStatus.ACCEPTED:
      return 'error';
    case ComplaintStatus.REJECTED:
      return 'warning';
    default:
      return 'info';
  }
};

export const getContentTypeName = (type: number): string => {
  switch (type) {
    case ExtendedUserContentType.ALL:
      return 'Все';
    case ExtendedUserContentType.COMMENT:
      return 'Комментарий';
    case ExtendedUserContentType.POST:
      return 'Пост';
    case ExtendedUserContentType.VIDEO:
      return 'Видео';
    case ExtendedUserContentType.STREAM:
      return 'Стрим';
    case ExtendedUserContentType.USER:
      return 'Пользователь';
    default:
      return 'Unknown Content Type';
  }
};
