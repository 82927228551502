import { createContext, useContext } from 'react';
import { UserModel } from '@src/types/models';

export type ContentItemCommentsContextType = {
  users: { [userId: string]: UserModel };
  isUserFetching: boolean;
};

export const ContentItemCommentsContext = createContext<ContentItemCommentsContextType>({
  isUserFetching: false,
  users: {},
});

export const useContentItemCommentsContext = () => useContext<ContentItemCommentsContextType>(ContentItemCommentsContext);
