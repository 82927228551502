import React, { useState } from 'react';
import { Box, BoxProps, Button, styled } from '@mui/material';
import ConfirmDialog from '@components/ConfirmDialog';

export type CardFormButtonGroupProps = BoxProps & {
  onSave: () => void;
  onCancel: () => void;
};

const CardFormButtonGroup = styled(({ onSave, onCancel, ...props }: CardFormButtonGroupProps) => {
  const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false);
  const dialogClose = () => setDialogIsOpen(false);
  const dialogOpen = () => setDialogIsOpen(true);

  return (
    <>
      <Box {...props}>
        <Button size="small" onClick={onCancel} sx={{ mr: 2 }}>
          Отменить
        </Button>
        <Button size="small" variant="contained" onClick={dialogOpen}>
          Сохранить
        </Button>
      </Box>
      <ConfirmDialog onCancel={dialogClose} onConfirm={onSave} open={dialogIsOpen} okText="Сохранить">
        Сохранить внесенные изменения?
      </ConfirmDialog>
    </>
  );
})(({ theme }) => ({
  display: 'flex',
  padding: theme.spacing(1.5, 0),
  justifyContent: 'end',
  '.MuiButton-root': {},
}));

export default CardFormButtonGroup;
