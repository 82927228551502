import React, { ReactNode } from 'react';
import { ListItem as MuiListItem, ListItemButton, ListItemIcon, ListItemProps as MuiListItemProps, ListItemText } from '@mui/material';
import { NavLink, NavLinkProps } from 'react-router-dom';

export type ListItemProps = MuiListItemProps &
  NavLinkProps & {
    label: string;
    icon?: ReactNode;
    end?: boolean;
  };

const ListItem = ({ label, icon, to, end, ...props }: ListItemProps) => {
  return (
    <MuiListItem {...props} disablePadding sx={{ display: 'block' }}>
      <NavLink to={to} end={end} className={({ isActive, isPending }) => (isPending ? 'pending' : isActive ? 'active' : '')}>
        <ListItemButton>
          {icon && <ListItemIcon>{icon}</ListItemIcon>}
          <ListItemText primary={label} />
        </ListItemButton>
      </NavLink>
    </MuiListItem>
  );
};

export default ListItem;
