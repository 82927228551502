import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

type CardHeaderProps = {
  editable: boolean;
  setEditable: (editable: boolean) => void;
};
const CardHeader = ({ editable, setEditable }: CardHeaderProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: 37,
        px: 3,
      }}
    >
      <Typography fontSize={15} fontWeight={700}>
        Общие данные
      </Typography>
      {!editable && (
        <Button
          sx={{
            fontSize: 14,
            fontWeight: 400,
            textTransform: 'none',
          }}
          onClick={() => setEditable(true)}
        >
          <EditIcon sx={{ mr: 0.5 }} />
          Редактировать
        </Button>
      )}
    </Box>
  );
};

export default CardHeader;
