import React, { useCallback, useState } from 'react';
import { BoxProps, Divider, OutlinedInput, OutlinedInputProps, styled, TableCell, TableCellProps, TableRow } from '@mui/material';
import UserStatusChip from '@components/UserCard/UserStatusChip';
import CardFormButtonGroup from '@components/CardFormButtonGroup';
import { UserModel, UserProfile } from '@src/types/models';
import { grey } from '@mui/material/colors';
import { black } from '@src/theme/colors';
import { UserCardFormData } from '@components/UserCard/types';
import { UserStatuses } from '@src/types';

type UserInfoTabProps = BoxProps & {
  user: UserModel;
  editable: boolean;
  cancelChanges: () => void;
  saveProfile: () => void;
};

type BaseFieldProps = Omit<OutlinedInputProps, 'name'> & {
  name: keyof UserProfile;
};

const BaseField = styled((props: BaseFieldProps) => {
  return <OutlinedInput {...props} autoComplete="off" size="small" fullWidth />;
})({
  backgroundColor: grey[50],
  '.MuiOutlinedInput-notchedOutline': {
    borderColor: black['A200'],
  },
});

const UserInfoCommonHeader = ({ user, editable, cancelChanges, saveProfile, ...props }: UserInfoTabProps) => {
  const [profile, setProfile] = useState<UserProfile>(Object.assign(user.profile || {}));
  const [formData, setFormData] = useState<UserCardFormData>(Object.assign(profile));
  const [userStatus, setUserStatus] = useState<UserStatuses>(UserStatuses.ACTIVE);
  const statusChangeHandler = (status: UserStatuses) => {
    switch (status) {
      case UserStatuses.ACTIVE:
        break;
      case UserStatuses.BLOCK:
        break;
      case UserStatuses.DELETE:
        break;
    }
  };

  const ProfileField = useCallback(
    ({ name, ...props }: BaseFieldProps) => {
      return (
        <BaseField
          {...props}
          defaultValue={profile[name]}
          name={name}
          onChange={e =>
            setFormData(prevState => ({
              ...prevState,
              [name]: e.target.value,
            }))
          }
        />
      );
    },
    [profile],
  );

  const LabelCell = useCallback(
    (props: TableCellProps) => {
      return (
        <TableCell
          {...props}
          sx={
            !editable
              ? {}
              : {
                  '&&': { pt: '17px' },
                }
          }
        />
      );
    },
    [editable],
  );

  return (
    <>
      <TableRow>
        <TableCell sx={{ '&&': { verticalAlign: 'middle' } }}>Статус</TableCell>
        <TableCell>
          <UserStatusChip onStatusChange={statusChangeHandler} userStatus={userStatus} editable={editable} />
        </TableCell>
      </TableRow>
      <TableRow>
        <LabelCell>Имя</LabelCell>
        <TableCell>{!editable ? profile?.firstname : <ProfileField name="firstname" />}</TableCell>
      </TableRow>
      <TableRow>
        <LabelCell>Фамилия</LabelCell>
        <TableCell>{!editable ? profile?.lastname : <ProfileField name="lastname" />}</TableCell>
      </TableRow>
      <TableRow>
        <LabelCell>Телефон</LabelCell>
        <TableCell>{!editable ? profile?.phone : <ProfileField name="phone" />}</TableCell>
      </TableRow>
      <TableRow>
        <LabelCell>Описание</LabelCell>
        <TableCell>{!editable ? profile?.about_me : <ProfileField name="about_me" multiline />}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={2}>
          {editable && <CardFormButtonGroup onCancel={cancelChanges} onSave={saveProfile} />}
          <Divider variant="fullWidth" />
        </TableCell>
      </TableRow>
    </>
  );
};

export default UserInfoCommonHeader;
