import React from 'react';
import { Avatar, Badge, BadgeProps, styled } from '@mui/material';
import ExpandMoreIcon from '@components/Layout/ExpandMoreIcon';
import { UserInfo } from '@src/types/models';

type HeaderBarAvatarProps = BadgeProps & {
  userInfo: UserInfo;
};

const HeaderBarAvatar = styled(({ userInfo, ...props }: HeaderBarAvatarProps) => {
  return (
    <Badge
      {...props}
      className={props.className + ' HeaderBarAvatar'}
      overlap="circular"
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      badgeContent={
        <Badge>
          <ExpandMoreIcon open={true} />
        </Badge>
      }
    >
      <Avatar>{userInfo.first_name && userInfo.last_name ? userInfo.first_name[0] + userInfo.last_name[0] : userInfo.email.slice(0, 2)}</Avatar>
    </Badge>
  );
})(({ theme }) => ({
  cursor: 'pointer',
  width: 40,
  height: 40,
  '.MuiAvatar-root': {
    backgroundColor: theme.palette.primary.main,
    textTransform: 'uppercase',
    fontSize: '16px',
  },
  '.MuiBadge-root': {
    background: '#FFF',
    borderRadius: '50%',
    boxShadow: '1px 1px 3px rgba(0, 0, 0, .2)',
    width: 16,
    height: 16,
    '.MuiSvgIcon-root': {
      color: theme.palette.primary.main,
      width: '100%',
      height: '100%',
    },
  },
}));

export default HeaderBarAvatar;
