import React from 'react';
import { BoxProps, Divider, TableCell, TableRow } from '@mui/material';
import { dateFormat } from '@utils/datetime';
import { UserModel } from '@src/types/models';

type UserInfoTabProps = BoxProps & {
  user: UserModel;
};
const UserInfoMidPart = ({ user, ...props }: UserInfoTabProps) => {
  return (
    <>
      <TableRow>
        <TableCell>Зарегистрирован</TableCell>
        <TableCell>{dateFormat(user.date_joined)}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Устройство</TableCell>
        <TableCell></TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={2}>
          <Divider variant="fullWidth" />
        </TableCell>
      </TableRow>
    </>
  );
};

export default UserInfoMidPart;
