import React, { useCallback } from 'react';
import { Box, BoxProps, OutlinedInput, OutlinedInputProps, styled, Typography } from '@mui/material';
import AvatarBadge from '@components/UserCard/AvatarBadge';
import { UserCardFormData } from '@components/UserCard/types';
import { UserModel, UserProfile } from '@src/types/models';
import { grey } from '@mui/material/colors';
import { black } from '@src/theme/colors';

type UserInfoTabProps = BoxProps & {
  user: UserModel;
};

type AvatarBadgeProps = {
  editable: boolean;
  formData: UserCardFormData;
  profile: UserProfile;
  onRemoveAvatar?: () => void;
  onRestoreAvatar?: () => void;
  setFormData: (data: UserCardFormData | ((prevState: UserCardFormData) => UserCardFormData)) => void; // Update type here
};

type BaseFieldProps = Omit<OutlinedInputProps, 'name'> & {
  name: keyof UserProfile;
};

const BaseField = styled((props: BaseFieldProps) => <OutlinedInput {...props} autoComplete="off" size="small" fullWidth />)({
  backgroundColor: grey[50],
  '.MuiOutlinedInput-notchedOutline': {
    borderColor: black['A200'],
  },
});

const AvatarContainer = ({
  user,
  editable,
  formData,
  profile,
  onRemoveAvatar,
  onRestoreAvatar,
  setFormData,
  ...props
}: UserInfoTabProps & AvatarBadgeProps) => {
  const ProfileField = useCallback(
    ({ name, ...props }: BaseFieldProps) => {
      return (
        <BaseField
          {...props}
          defaultValue={profile[name] || ''}
          name={name}
          onChange={e =>
            setFormData(
              (prevState: UserProfile): UserProfile => ({
                ...prevState,
                [name]: e.target.value,
              }),
            )
          }
        />
      );
    },
    [profile, setFormData],
  );

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', my: 1 }}>
      <AvatarBadge
        profile={profile}
        formData={formData}
        editable={editable}
        onRemoveAvatar={() => {
          setFormData((prevState: UserProfile) => ({ ...prevState, picture: null }));
        }}
        onRestoreAvatar={() => {
          setFormData((prevState: UserProfile) => ({
            ...prevState,
            picture: profile.picture,
          }));
        }}
      />
      {!editable ? <Typography>{profile?.nickname}</Typography> : <ProfileField name="nickname" />}
    </Box>
  );
};

export default AvatarContainer;
