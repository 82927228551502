import React, { ForwardedRef, forwardRef } from 'react';
import { Popover, PopoverProps } from '@mui/material';
import { PopoverOrigin } from '@mui/material/Popover/Popover';

export type DropDownPopoverProps = PopoverProps & {
  alignX?: PopoverOrigin['horizontal'];
  alignY?: PopoverOrigin['vertical'];
};

const DropDownPopover = forwardRef(({ alignX = 'left', alignY = 'bottom', ...props }: DropDownPopoverProps, ref: ForwardedRef<HTMLDivElement>) => {
  return (
    <Popover
      {...props}
      ref={ref}
      anchorOrigin={{
        vertical: alignY,
        horizontal: alignX,
      }}
      transformOrigin={{
        vertical: alignY === 'top' ? 'bottom' : 'top',
        horizontal: alignX,
      }}
    />
  );
});

export default DropDownPopover;
