import React, { useEffect, useRef, useState } from 'react';
import { useAddRoleToUserMutation, useGetRolesForUserQuery, useGetUserInfoByEmailQuery, useRemoveRoleToUserMutation } from '@api/admin_user';
import HeaderBar from '@components/Layout/HeaderBar';
import LayoutBody from '@components/Layout/LayoutBody';
import {
  Alert,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const SearchAdmin = () => {
  const emailRef = useRef<HTMLInputElement | null>(null);
  const hasSearchedRef = useRef<boolean>(false);
  const [selectedRole, setSelectedRole] = useState<string>('Нет роли');
  const [email, setEmail] = useState<string>('');

  const [successAlert, setSuccessAlert] = useState<boolean>(false);
  const [errorAlert, setErrorAlert] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>('');

  const {
    data: userData,
    isLoading: isLoadingUser,
    isError: isLoadingUserError,
    isFetching: isFetchingUser,
  } = useGetUserInfoByEmailQuery(email, {
    skip: !email,
  });

  const userId = userData ? userData.user_id : null;

  const { data: rolesData, isLoading: isLoadingRoles } = useGetRolesForUserQuery(userId, {
    skip: !userId,
  });

  const [addRoleToUser, { isLoading: isLoadingAdd }] = useAddRoleToUserMutation();
  const [removeRoleFromUser, { isLoading: isLoadingRemove }] = useRemoveRoleToUserMutation();

  useEffect(() => {
    if (rolesData) {
      const hasSuperadminRole = rolesData.includes('superadmin');
      setSelectedRole(hasSuperadminRole ? 'Суперадмин' : 'Нет роли');
    }
  }, [rolesData]);

  const searchUser = () => {
    if (emailRef.current) {
      setEmail(emailRef.current.value);
      hasSearchedRef.current = true;
      emailRef.current.value = '';
    }
  };
  const handleRoleChange = async (event: SelectChangeEvent<string>) => {
    const newRole = event.target.value as string;
    setSelectedRole(newRole);

    try {
      if (newRole === 'Нет роли' && userId) {
        await removeRoleFromUser({
          userId,
          role: 'superadmin',
        }).unwrap();
        setAlertMessage('Пользователь лишен роли Суперадмин');
        setSuccessAlert(true);
      } else if (newRole === 'Суперадмин' && userId) {
        await addRoleToUser({ userId, role: 'superadmin' }).unwrap();
        setAlertMessage('Пользователю присвоена роль СуперАдмина');
        setSuccessAlert(true);
      }
    } catch (error) {
      console.error('Ошибка при обновлении роли:', error);
      setAlertMessage('Ошибка при обновлении роли');
      setErrorAlert(true);
    }
  };

  return (
    <>
      <HeaderBar />
      <LayoutBody>
        <Typography variant="h6" gutterBottom>
          Поиск по email
        </Typography>
        <div>
          <Stack spacing={2} direction="row">
            <TextField size={'small'} error={isLoadingUserError && hasSearchedRef.current} inputRef={emailRef} id="outlined-error" label="email" />
            <Button size={'small'} variant="contained" onClick={searchUser}>
              Поиск
            </Button>
          </Stack>
        </div>

        {hasSearchedRef.current && (
          <Card
            sx={{
              maxWidth: 360,
              marginTop: 2,
              position: 'relative',
            }}
          >
            {isFetchingUser ? (
              <CardContent>
                <CircularProgress />
              </CardContent>
            ) : isLoadingUserError || !userData ? (
              <CardContent>
                <Typography variant="h6" component="div">
                  Пользователь не найден
                </Typography>
              </CardContent>
            ) : (
              <>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    {userData.email}
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    {userData.first_name}
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    {userData.last_name}
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    {userData.user_id}
                  </Typography>
                </CardContent>

                <CardActions>
                  {isLoadingRoles && <CircularProgress />}
                  {rolesData && (
                    <FormControl variant="outlined" margin="normal" fullWidth>
                      <InputLabel id="role-select-label">Выберите роль</InputLabel>
                      <Select labelId="role-select-label" value={selectedRole} onChange={handleRoleChange} label="Выберите роль">
                        <MenuItem value="Нет роли">Нет роли</MenuItem>
                        <MenuItem value="Суперадмин">Суперадмин</MenuItem>
                      </Select>
                    </FormControl>
                  )}
                </CardActions>
              </>
            )}
          </Card>
        )}

        {successAlert && (
          <Alert
            severity="success"
            action={
              <Button color="inherit" onClick={() => setSuccessAlert(false)} startIcon={<CloseIcon />}>
                Закрыть
              </Button>
            }
            sx={{ margin: '1rem 0' }}
          >
            {alertMessage}
          </Alert>
        )}

        {errorAlert && (
          <Alert
            severity="error"
            action={
              <Button color="inherit" onClick={() => setErrorAlert(false)} startIcon={<CloseIcon />}>
                Закрыть
              </Button>
            }
            sx={{ margin: '1rem 0' }}
          >
            {alertMessage}
          </Alert>
        )}
      </LayoutBody>
    </>
  );
};

export default SearchAdmin;
