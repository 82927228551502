import React, { cloneElement, ReactElement, ReactNode, useRef, useState } from 'react';
import { Box, Button, ButtonProps, List, styled, SxProps } from '@mui/material';
import DropDownPopover, { DropDownPopoverProps } from '@components/DropDownPopover';

export type DropDownProps = Omit<DropDownPopoverProps, 'open'> & {
  label: ReactNode;
  sx?: SxProps;
  className?: string;
  button?: ReactElement<ButtonProps>;
};

const DropDown = styled(({ children, label, sx, className, button, ...props }: DropDownProps) => {
  const [menuAnchorRef, setMenuAnchorRef] = useState<HTMLButtonElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const btnRef = useRef<HTMLButtonElement | null>(null);

  if (!button) {
    button = <Button />;
  }

  return (
    <Box className={className + ' DropDown'} sx={sx} ref={containerRef}>
      {cloneElement(button, {
        onClick: () => setMenuAnchorRef(btnRef.current),
        children: label,
        ref: btnRef,
      })}
      <DropDownPopover {...props} onClose={() => setMenuAnchorRef(null)} container={containerRef.current} anchorEl={menuAnchorRef} open={!!menuAnchorRef}>
        <List onClick={() => setMenuAnchorRef(null)}>{children}</List>
      </DropDownPopover>
    </Box>
  );
})(({ theme }) => ({
  '.MuiButton-root': {
    minWidth: 'auto',
  },
  '.MuiListItemIcon-root': {
    minWidth: 38,
  },
  '.MuiListItem-root': {
    '&.active .MuiListItemButton-root': {
      backgroundColor: 'rgba(0, 0, 0, 0.06)',
    },
  },
  '.MuiListItemButton-root': {
    paddingRight: theme.spacing(4),
  },
}));

export default DropDown;
