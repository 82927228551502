import React from 'react';
import { Route, Routes } from 'react-router-dom';
import NotFoundPage from '@pages/NotFound';
import LogoutPage from '@pages/Logout';
import { UserStatusTypes } from '@pages/Users';
import { UserContentTypes } from '@src/types';
import SearchAdmin from '@pages/SearchAdmin';
import UploadFilesAdmin from '@pages/UploadFilesAdmin';
import TransferVideo from '@components/TransferVideo/TransferVideo';

const HomePage = React.lazy(() => import('@pages/Home'));
const UsersPage = React.lazy(() => import('@pages/Users'));
const SettingsPage = React.lazy(() => import('@pages/Settings'));
const UserContentsPage = React.lazy(() => import('@pages/UserContents'));
const CommentsPage = React.lazy(() => import('@pages/Comments'));
const UserInfoPage = React.lazy(() => import('@pages/UserInfo'));
const ContentInfoPage = React.lazy(() => import('@pages/ContentInfo'));
const PaymentsPage = React.lazy(() => import('@pages/Payments'));
const ComplaintsPage = React.lazy(() => import('@pages/Complaints'));
const TransferPage = React.lazy(() => import('@pages/Transfer'));

const AppRoutes = () => {
  return (
    <Routes>
      <Route index element={<HomePage />} />
      <Route path="users">
        <Route index element={<UsersPage status={UserStatusTypes.ALL} />} />
        <Route path="new" element={<UsersPage status={UserStatusTypes.NEW} />} />
        <Route path=":userId" element={<UserInfoPage />} />
      </Route>
      <Route path="content">
        <Route index element={<UserContentsPage />} />
        <Route path="posts" element={<UserContentsPage type={UserContentTypes.POST} />} />
        <Route path="videos" element={<UserContentsPage type={UserContentTypes.VIDEO} />} />
        <Route path="streams" element={<UserContentsPage type={UserContentTypes.STREAM} />} />
        <Route path="stories" element={<UserContentsPage type={UserContentTypes.STORIES} />} />
        <Route path="comments" element={<CommentsPage />} />
        <Route path=":contentId" element={<ContentInfoPage />} />
      </Route>
      <Route path="/complaints" element={<ComplaintsPage />}></Route>
      <Route path="logout" element={<LogoutPage />} />
      <Route path="*" element={<NotFoundPage />} />
      <Route path="payments" element={<PaymentsPage />}></Route>
      <Route path="/services" element={<TransferPage />}>
        <Route path="translation" element={<TransferVideo />} />
      </Route>
      <Route path="/settings">
        <Route path="admins" element={<SettingsPage status={UserStatusTypes.ALL} />} />
        <Route path="log" element={<NotFoundPage />} />
        <Route path="search" element={<SearchAdmin />} />
        <Route path="upload" element={<UploadFilesAdmin />} />
      </Route>
    </Routes>
  );
};

export default AppRoutes;
