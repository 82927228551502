import React from 'react';
import { Box, Drawer, DrawerProps, styled } from '@mui/material';

const CardDrawer = styled(({ children, ...props }: DrawerProps) => {
  return (
    <Drawer {...props} anchor="right">
      <Box className="CardDrawer__inner">{children}</Box>
    </Drawer>
  );
})(({ theme }) => ({
  '.CardDrawer__inner': {
    display: 'flex',
    flexDirection: 'column',
    // width: 400,
    width: 824,
    height: '100%',
  },
  hr: {
    border: 'none',
    height: 1,
    background: 'rgba(0,0,0,0.1)',
    margin: theme.spacing(1.5, 0),
  },
}));

export default CardDrawer;
