import React, { useEffect, useMemo, useState } from 'react';
import DocumentTitle from '@components/DocumentTitle';
import { MenuItem, Select } from '@mui/material';
import { useUserListQuery } from '@api/user';
import Loading from '@components/Loading';
import { UserModel } from '@src/types/models';
import { useLocalStorage } from 'usehooks-ts';
import { DEFAULT_PER_PAGE } from '@src/config';
import { useSearchParams } from 'react-router-dom';
import CommonTable from '@components/CommonTable';
import HeaderBar from '@components/Layout/HeaderBar';
import PageTitle from '@components/PageTitle';
import { validateUuidV4 } from '@utils/validators';
import AddFilterButton from '@components/AddFilterButton';
import SearchButton from '@components/SearchButton';
import SearchInput from '@components/SearchInput';
import EmptyResult from '@components/EmptyResult';
import PageTitleRow from '@components/PageTitleRow';
import ErrorSnackbar from '@components/ErrorSnackbar';
import SearchForm from '@components/Layout/SearchForm';
import InputGroup from '@components/InputGroup';
import AccessDenied from '@components/AccessDenied';
import LayoutBody from '@components/Layout/LayoutBody';
import { dateFormat } from '@utils/datetime';
import UserCardDrawer from '@components/UserCardDrawer';

export enum UserStatusTypes {
  ALL = 'all',
  NEW = 'new',
}

export enum SearchByTypes {
  ALL = 'all',
  NICK = 'nickname',
  FIO = 'fio',
  TEL = 'tel',
  ID = 'id',
}

type UsersPageType = {
  status?: UserStatusTypes;
};

const UsersPage = ({ status = UserStatusTypes.ALL }: UsersPageType) => {
  const [rowsPerPage, setRowsPerPage] = useLocalStorage<number>('usersPerPage', DEFAULT_PER_PAGE);
  const [orderBy, setOrderBy] = useState<string | undefined>('date_joined');
  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [searchInputValue, setSearchInputValue] = useState<string>('');
  const [searchByType, setSearchByType] = useState<SearchByTypes>(SearchByTypes.NICK);
  const [filter, setFilter] = useState<{
    nickname?: string;
    id__in?: string[];
  }>({});
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchInputError, setSearchInputError] = useState<string | null>(null);
  const [errorSnackbarOpen, setErrorSnackbarOpen] = useState<boolean>(false);
  const [activeUser, setActiveUser] = useState<UserModel | null>(null);
  const [users, setUsers] = useState<UserModel[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);

  const page = useMemo(() => {
    const page = Number(searchParams.get('page') || '1');
    return page > 0 ? page : 1;
  }, [searchParams]);

  const { data, isFetching, error } = useUserListQuery({
    page,
    page_size: rowsPerPage,
    order: orderBy && (order === 'asc' ? orderBy : `-${orderBy}`),
    filter,
  });

  const existsSearchValue = useMemo(() => !!searchInputValue.trim().length, [searchInputValue]);

  const forbidden = useMemo(() => error && 'status' in error && error.status === 403, [error]);

  const existsFilters = useMemo(() => !!Object.values(filter || {}).filter(v => v && v.length > 0).length, [filter]);

  useEffect(() => {
    setSearchInputError(null);
  }, [searchInputValue, searchByType]);

  useEffect(() => {
    if (!isFetching && data) {
      setTotalCount(data.pagination.total_count);
      setUsers(data.results);
    }
  }, [data, isFetching]);

  const setPage = (page: number) => {
    setSearchParams(prev => {
      prev.set('page', (page + 1).toString());
      return prev;
    });
  };

  const pageTitle = useMemo(() => {
    const pageTitle = 'Пользователи / ';
    if (!isFetching && existsFilters) {
      return `${pageTitle}Совпадений найдено: ${totalCount}`;
    }
    switch (status) {
      case UserStatusTypes.ALL:
        return `${pageTitle}Все`;
      case UserStatusTypes.NEW:
        return `${pageTitle}Новые`;
    }
  }, [status, totalCount]);

  const validate = () => {
    setSearchInputError(null);
    setErrorSnackbarOpen(false);
    const value = searchInputValue.trim();
    if (!value) {
      return false;
    }
    let error = null;
    switch (searchByType) {
      case SearchByTypes.ID:
        if (!validateUuidV4(value)) {
          error = 'Не валидный ID';
        }
        break;
    }
    if (!error) {
      return true;
    }
    setSearchInputError(error);
    setErrorSnackbarOpen(true);
    return false;
  };
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleSortOrder = (property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const submitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!validate()) {
      return;
    }
    setFilter(filter => {
      const value = searchInputValue.trim();
      switch (searchByType) {
        case SearchByTypes.NICK:
          return { ...filter, nickname: value };
        case SearchByTypes.ID:
          return { ...filter, id__in: [value] };
        default:
          return {};
      }
    });
    setPage(0);
  };

  const clearSearchField = () => {
    setSearchInputValue('');
    setFilter(filter => {
      switch (searchByType) {
        case SearchByTypes.NICK:
          return { ...filter, nickname: undefined };
        case SearchByTypes.ID:
          return { ...filter, id__in: undefined };
        default:
          return {};
      }
    });
    setPage(0);
  };

  const userColumns = [
    { tableHeadTitle: 'ID' },
    { tableHeadTitle: 'Ник' },
    { tableHeadTitle: 'Имя' },
    { tableHeadTitle: 'Фамилия' },
    { tableHeadTitle: 'Дата регистрации', sortKey: 'date_joined' },
    { tableHeadTitle: 'Последняя активность', sortKey: 'last_login' },
  ];

  const rowData = (user: UserModel) => {
    return [user.id, user.profile?.nickname, user.profile?.firstname, user.profile?.lastname, dateFormat(user.date_joined), dateFormat(user.last_login)];
  };
  const closeUserCardDrawer = () => {
    setActiveUser(null);
  };
  const openUserCardDrawer = (user: UserModel) => {
    setActiveUser(user);
  };

  return (
    <>
      <DocumentTitle>{pageTitle}</DocumentTitle>
      {isFetching && <Loading />}
      <ErrorSnackbar open={errorSnackbarOpen} onClose={() => setErrorSnackbarOpen(false)}>
        {searchInputError}
      </ErrorSnackbar>
      <HeaderBar>
        {!forbidden && (
          <>
            <SearchForm onSubmit={submitHandler}>
              <InputGroup>
                <SearchInput
                  onFocus={() => setSearchInputError(null)}
                  onChange={e => setSearchInputValue(e.target.value)}
                  onClear={clearSearchField}
                  value={searchInputValue}
                />
                <Select size="small" value={searchByType} onChange={e => setSearchByType(e.target.value as SearchByTypes)}>
                  <MenuItem value={SearchByTypes.ALL} disabled>
                    Везде
                  </MenuItem>
                  <MenuItem value={SearchByTypes.NICK}>Ник</MenuItem>
                  <MenuItem value={SearchByTypes.FIO} disabled>
                    Имя/Фамилия
                  </MenuItem>
                  <MenuItem value={SearchByTypes.TEL} disabled>
                    Телефон
                  </MenuItem>
                  <MenuItem value={SearchByTypes.ID}>ID</MenuItem>
                </Select>
              </InputGroup>
              <SearchButton type="submit" disabled={!existsSearchValue && !searchInputError} sx={{ ml: 1 }} />
            </SearchForm>
            <AddFilterButton sx={{ ml: 3 }} />
          </>
        )}
      </HeaderBar>
      <LayoutBody>
        <PageTitleRow existsFilters={existsFilters} onResetFilters={clearSearchField}>
          <PageTitle>{pageTitle}</PageTitle>
        </PageTitleRow>
        {existsFilters && !totalCount && <EmptyResult />}
        {forbidden && <AccessDenied />}
        {users.length > 0 && (
          <CommonTable
            onRowsPerPageChange={handleChangeRowsPerPage}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            count={totalCount}
            page={page - 1}
            orderBy={orderBy}
            order={order}
            data={users}
            onSort={handleSortOrder}
            columns={userColumns}
            rowData={rowData}
            onRowClick={openUserCardDrawer}
          />
        )}
      </LayoutBody>
      <UserCardDrawer user={activeUser} onClose={closeUserCardDrawer} />
    </>
  );
};
export default UsersPage;
