import React from 'react';
import { ButtonBase, ButtonBaseProps, styled } from '@mui/material';
import { indigo } from '@mui/material/colors';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { black } from '@src/theme/colors';

type FilterMoreButtonProps = ButtonBaseProps & {
  open?: boolean;
};

const FilterMoreButton = styled(({ open, children, ...props }: FilterMoreButtonProps) => (
  <ButtonBase {...props}>
    {children}
    {!open ? <ExpandMore /> : <ExpandLess />}
  </ButtonBase>
))(({ theme }) => ({
  color: black[50],
  backgroundColor: indigo[50],
  padding: theme.spacing(0, 0.5, 0, 1),
  borderRadius: theme.spacing(2),
  fontSize: 12,
  height: 24,
  '&&[hidden]': {
    visibility: 'hidden',
    overflow: 'hidden',
    position: 'absolute',
    width: 0,
    height: 0,
    margin: 0,
  },
  '.MuiSvgIcon-root': {
    marginLeft: theme.spacing(0.25),
    color: black[300],
    height: 20,
    width: 20,
  },
}));

export default FilterMoreButton;
