import { useState } from 'react';
import HeaderBar from '@components/Layout/HeaderBar';
import LayoutBody from '@components/Layout/LayoutBody';
import Session from 'supertokens-web-js/recipe/session';
import { Button, Stack, Typography } from '@mui/material';

const SearchAdmin = () => {
  const [imageUrl, setImageUrl] = useState(null);

  const handleFileUpload: React.ChangeEventHandler<HTMLInputElement> = e => {
    if (e.target.files && e.target.files[0]) {
      uploadFile(e.target.files[0]);
    }
  };

  const uploadFile = async (file: File) => {
    const formData = new FormData();
    formData.append('file', file);

    const accessToken = await Session.getAccessToken();

    const result = await fetch('https://media.api.tach.id/api/v1/images/', {
      method: 'POST',
      body: formData,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const response = await result.json();

    setImageUrl(response.id);
  };

  return (
    <>
      <HeaderBar />
      <LayoutBody>
        <Typography variant="h6" gutterBottom>
          Загрузка изображений
        </Typography>
        <div>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Button variant="contained" component="label">
              Upload
              <input onChange={handleFileUpload} hidden accept="image/*" multiple type="file" />
            </Button>
            <div>{imageUrl}</div>
          </Stack>
        </div>
      </LayoutBody>
    </>
  );
};

export default SearchAdmin;
