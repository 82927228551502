import React from 'react';
import { styled, Typography, TypographyProps } from '@mui/material';

const PageTitle = styled((props: TypographyProps) => <Typography {...props} variant="h1" />)(({ theme: { palette, spacing } }) => ({
  fontSize: '15px',
  fontWeight: 700,
  letterSpacing: 0.15,
  color: palette.primary.main,
  margin: spacing(1, 0, 2, 1),
}));

export default PageTitle;
