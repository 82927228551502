import { BaseContentStatuses, ExplicitTypes, ModerationTypes, UserContentTypes } from '@src/types/index';

export type UserProfile = {
  id: string;
  city_id: string | null;
  city: string | null;
  country_id: string | null;
  country: string | null;
  currency: string | null;
  date_registered: string;
  firstname: string;
  lastname: string;
  nickname: string;
  gender: string | null;
  birthdate: string | null;
  picture: string | null;
  original_picture: string | null;
  phone: string;
  about_me: string | null;
  properties: string | null;
  following: string[];
  follower: string[];
};

export type UserModel = {
  id: string;
  is_active: boolean;
  is_purged: boolean;
  groups: string[];
  date_joined: string;
  last_login: string;
  email: string | null;
  email_verified: boolean | null;
  profile: UserProfile | null;
};

export type UserInfo = {
  user_id: string;
  email: string;
  time_joined: number;
  first_name?: string;
  last_name?: string;
  tenant_ids: Array<'public'>;
};

export type UserContentCounters = {
  content_comments: number;
  content_like_both: number;
  content_like_dislike: number;
  content_like_like: number;
};

export type UserCounters = {
  comments_user: number;
  content_post_user: number;
  content_user: number;
  content_video_user: number;
  content_vstream_user: number;
  followers_user: number;
  like_both_user: number;
  like_dislike_user: number;
  like_like_user: number;
  subscriptions_user: number;
  user_content_like_both: number;
  user_content_like_dislike: number;
  user_content_like_like: number;
};

export enum ContentLocationStatuses {
  LIVE = 0,
  ARCHIVE = 1,
}
export enum Languages {
  RU = 'ru',
  EN = 'en',
}

export type BaseContentsModel<T = {}> = {
  id: string;
  status: BaseContentStatuses;
  type: UserContentTypes;
  created: string;
  published: string;
  updated: string;
  explicit: ExplicitTypes;
  moderation: ModerationTypes;
  retailmention: number;
  markers: number[];
  user_id: string;
  content: DifContentType & T;
};

export type CommentContentType = {
  id: string;
  text: string;
  language: Languages;
  commented_content: string;
  parent: string | null;
};

export type UserCommentContentModel = BaseContentsModel<CommType>;
export type UserPostContentModel = BaseContentsModel<PostType>;
export type UserVideoContentModel = BaseContentsModel<VideoType>;

export type DifContentType = {
  id: string;
  language: Languages;
  title?: string;
  text?: string;
  description?: string;
};

export type PostType = {
  content: string;
  text: string;
  images: ImagesItemType[];
};

type ImagesItemType = {
  height: number;
  image: string;
  width: number;
};

export type VideoType = {
  content: string;
  title: string;
  description: string;
  uuid: string;
  location_status: number;
};

export type CommType = {
  text: string;
  commented_content: string;
  parent: string | null;
};
