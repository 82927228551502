import React from 'react';
import { Box, BoxProps, CircularProgress, styled, Typography } from '@mui/material';
import { indigo } from '@mui/material/colors';
import { black } from '@src/theme/colors';

type CounterChipProps = BoxProps & {
  label: string;
  value?: number;
  loading?: boolean;
};

const CounterChip = styled(({ value, label, className, loading, ...props }: CounterChipProps) => {
  return (
    <Box {...props} className={className + ' AppCounterChip'}>
      <Typography fontSize={16} fontWeight={700}>
        {loading ? <CircularProgress size={16} /> : typeof value !== 'undefined' ? value : '?'}
      </Typography>
      <Typography fontSize={12} fontWeight={400}>
        {label}
      </Typography>
    </Box>
  );
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',
  padding: theme.spacing(1, 1.5),
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${indigo[50]}`,
  color: black[50],
}));

export default CounterChip;
