import React, { useMemo, useState } from 'react';
import { Button, ButtonProps, List, ListItem, ListItemButton, ListItemText, styled } from '@mui/material';
import { black } from '@src/theme/colors';
import { lightGreen, red } from '@mui/material/colors';
import { ExpandMore } from '@mui/icons-material';
import { UserStatuses } from '@src/types';
import DropDownPopover from '@components/DropDownPopover';

export type StatusChipProps = ButtonProps & {
  onStatusChange: (status: UserStatuses) => void;
  userStatus: UserStatuses;
  editable: boolean;
};

const UserStatusChip = styled(({ userStatus, editable, onStatusChange, ...props }: StatusChipProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [status, setStatus] = useState<UserStatuses>(userStatus);

  const statusTitle = useMemo(() => {
    switch (status) {
      case UserStatuses.DELETE:
        return 'Удалён';
      case UserStatuses.BLOCK:
        return 'Заблокирован';
      case UserStatuses.ACTIVE:
        return 'Активный';
    }
  }, [status]);

  const statusColor = useMemo(() => {
    switch (status) {
      case UserStatuses.DELETE:
      case UserStatuses.BLOCK:
        return red[100];
      case UserStatuses.ACTIVE:
        return lightGreen[100];
    }
  }, [status]);

  const statusChange = (status: Parameters<StatusChipProps['onStatusChange']>[0]) => {
    setAnchorEl(null);
    onStatusChange(status);
    setStatus(status);
  };

  return (
    <>
      <Button
        {...props}
        disableRipple={!editable}
        onClick={e => {
          editable && setAnchorEl(e.currentTarget);
        }}
        sx={{
          '&, :hover': {
            background: statusColor,
          },
        }}
      >
        {statusTitle}
        {editable && <ExpandMore />}
      </Button>
      {editable && (
        <DropDownPopover open={!!anchorEl} anchorEl={anchorEl} id={anchorEl ? 'user-status-popover' : undefined} onClose={() => setAnchorEl(null)}>
          <List sx={{ width: 200 }}>
            <ListItem disablePadding>
              <ListItemButton onClick={() => statusChange(UserStatuses.ACTIVE)}>
                <ListItemText primary="Активный" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={() => statusChange(UserStatuses.BLOCK)}>
                <ListItemText primary="Заблокирован" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton disabled>
                <ListItemText primary="Readonly" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={() => statusChange(UserStatuses.DELETE)}>
                <ListItemText primary="Удалён" />
              </ListItemButton>
            </ListItem>
          </List>
        </DropDownPopover>
      )}
    </>
  );
})(({ theme, editable }) => {
  return {
    padding: theme.spacing(0, editable ? 0.5 : 1.25, 0, 1.25),
    borderRadius: theme.spacing(2),
    cursor: editable ? 'pointer' : 'default',
    textTransform: 'none',
    fontSize: '0.8125rem',
    height: 24,
    '&, :hover': {
      color: black[50],
    },
    '.MuiSvgIcon-root': {
      fontSize: 18,
    },
  };
});

export default UserStatusChip;
