import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQueryConfig } from '@utils/api';
import { UserInfo } from '@src/types/models';
import { SUPER_TOKEN_API_HOST } from '@src/config';

export const adminUserApi = createApi({
  reducerPath: 'adminUserApi',
  baseQuery: baseQueryConfig({ baseUrl: SUPER_TOKEN_API_HOST }),
  endpoints: build => ({
    getUserInfo: build.query<UserInfo, void>({
      query: () => ({
        url: 'my_user_info/',
      }),
    }),
    getUserInfoByEmail: build.query<UserInfo, string>({
      query: email => ({
        url: `user_info_by_email/${encodeURIComponent(email)}`,
      }),
    }),
    getRolesForUser: build.query<any, any>({
      query: userId => {
        return {
          url: `get_roles_for_user?user_id=${encodeURIComponent(userId)}`,
        };
      },
    }),
    addRoleToUser: build.mutation<void, { userId: string; role: string }>({
      query: ({ userId, role }) => ({
        url: `add_role_to_user?user_id=${encodeURIComponent(userId)}&role=${encodeURIComponent(role)}`,
        method: 'POST',
        headers: {
          accept: 'application/json',
        },
        body: {},
      }),
    }),
    removeRoleToUser: build.mutation<void, { userId: string; role: string }>({
      query: ({ userId, role }) => ({
        url: `remove_role_from_user?user_id=${encodeURIComponent(userId)}&role=${encodeURIComponent(role)}`,
        method: 'POST',
        headers: {
          accept: 'application/json',
        },
        body: {},
      }),
    }),
  }),
});

export const { useGetUserInfoQuery, useGetUserInfoByEmailQuery, useGetRolesForUserQuery, useAddRoleToUserMutation, useRemoveRoleToUserMutation } = adminUserApi;
