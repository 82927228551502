import { Button, styled } from '@mui/material';
import { black } from '@src/theme/colors';

const ClearFiltersButton = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  fontSize: 14,
  color: black[50],
  marginLeft: theme.spacing(3),
  padding: 0,
}));

export default ClearFiltersButton;
