import React from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';
import { ListItem, ListItemButton, ListItemIcon, ListItemProps, ListItemText, styled } from '@mui/material';

type HeaderBarMenuItemProps = ListItemProps &
  NavLinkProps & {
    icon: React.ReactNode;
  };

const HeaderBarMenuItem = styled(({ to, end, children, icon, ...props }: HeaderBarMenuItemProps) => {
  return (
    <ListItem {...props} disablePadding>
      <NavLink to={to} end={end}>
        <ListItemButton>
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText primary={children} />
        </ListItemButton>
      </NavLink>
    </ListItem>
  );
})(({ theme }) => ({
  '> a': {
    textDecoration: 'none',
    color: 'inherit',
    display: 'block',
    width: '100%',
  },
  '.MuiListItemIcon-root': {
    minWidth: 0,
    marginRight: theme.spacing(2.5),
  },
  '.MuiListItemButton-root': {
    // height: 56,
    '.MuiTypography-root': {
      fontSize: 16,
    },
  },
}));

export default HeaderBarMenuItem;
