import React from 'react';
import LayoutBody from '@components/Layout/LayoutBody';
import HeaderBar from '@components/Layout/HeaderBar';
import PageNotFound from '@components/PageNotFound';

const NotFoundPage = () => {
  return (
    <>
      <HeaderBar />
      <LayoutBody>
        <PageNotFound sx={{ mt: 2 }} />
      </LayoutBody>
    </>
  );
};

export default NotFoundPage;
