import React, { useEffect } from 'react';
import { Box, BoxProps, CssBaseline, Toolbar } from '@mui/material';
import Drawer from '@components/Layout/Drawer';
import SideBar from '@components/Layout/SideBar';
import { LogoIcon } from '@components/icons';
import { useLocation } from 'react-router-dom';

type LayoutProps = BoxProps & {};

const Layout: React.FC<LayoutProps> = ({ children, ...props }) => {
  const { pathname, search } = useLocation();

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({
        behavior: 'smooth',
        top: 0,
      });
    });
  }, [pathname, search]);

  return (
    <Box {...props}>
      <CssBaseline />

      <Box sx={{ display: 'flex' }}>
        <Drawer variant="persistent" anchor="left" open={true}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <LogoIcon sx={{ width: 'auto', height: 30 }} />
          </Toolbar>
          <SideBar />
        </Drawer>

        <Box sx={{ flexGrow: 1 }}>
          <Toolbar />
          {children}
        </Box>
      </Box>
    </Box>
  );
};

export default Layout;
