import React from 'react';
import { Alert, Snackbar, SnackbarProps } from '@mui/material';

export type ErrorSnackbarProps = Omit<SnackbarProps, 'children'> & {
  open?: boolean;
  onClose?: () => void;
  children?: React.ReactNode;
};

const ErrorSnackbar = ({ open, onClose, children, ...props }: ErrorSnackbarProps) => {
  return (
    <Snackbar
      {...props}
      open={open}
      autoHideDuration={6000}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <Alert onClose={onClose} severity="error" variant="standard" sx={{ width: '100%' }}>
        {children}
      </Alert>
    </Snackbar>
  );
};

export default ErrorSnackbar;
