import React, { useEffect } from 'react';

type DocumentTitleProps = {
  children: string;
};

const DocumentTitle = ({ children }: DocumentTitleProps) => {
  useEffect(() => {
    document.title = 'TACH Admin / ' + children;
  }, [children]);

  return <></>;
};

export default DocumentTitle;
