import React from 'react';
import { Box, BoxProps, styled } from '@mui/material';
import ComplaintChip from '@components/UserCard/ComplaintChip';
import CounterChip from '@components/UserCard/CounterChip';
import { useUserCountersQuery } from '@api/user';
import { useGetComplaintsListQuery } from '@api/complaints';

type CountersProps = BoxProps & {
  userId: string;
};

const Counters = styled(({ userId, ...props }: CountersProps) => {
  const { isLoading, data } = useUserCountersQuery(userId);

  const { data: complaintsSubmittedByUser } = useGetComplaintsListQuery({
    filter: {
      user_id: userId,
    },
  });

  return (
    <Box {...props}>
      <ComplaintChip label="Жалобы на пользователя" />
      <ComplaintChip label="Жалоб отправлено пользователем" value={complaintsSubmittedByUser?.results?.length} />
      <Box sx={{ width: '100%' }} />
      <CounterChip loading={isLoading} label="Контент" value={data?.content_user} />
      <CounterChip loading={isLoading} label="Посты" value={data?.content_post_user} />
      <CounterChip loading={isLoading} label="Видео" value={data?.content_video_user} />
      <CounterChip loading={isLoading} label="Стримы" value={data?.content_vstream_user} />
      <CounterChip loading={isLoading} label="Комментарии" value={data?.comments_user} />
      <CounterChip loading={isLoading} label="Подписки" value={data?.subscriptions_user} />
      <CounterChip loading={isLoading} label="Подписчики" value={data?.followers_user} />
    </Box>
  );
})(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  '.AppCounterChip, .AppComplaintChip': {
    margin: theme.spacing(0, 1, 1, 0),
  },
}));

export default Counters;
