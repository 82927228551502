import { Box, styled } from '@mui/material';
import { black } from '@src/theme/colors';

const InputGroup = styled(Box)(() => ({
  display: 'flex',
  flexGrow: 1,

  '.MuiFormControl-root': {
    '.MuiOutlinedInput-root': {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,

      '.MuiOutlinedInput-notchedOutline': {
        borderRight: 'none',
      },
    },

    '+ .MuiOutlinedInput-root': {
      borderBottomLeftRadius: 0,
      borderTopLeftRadius: 0,
      marginLeft: '-1px',
      width: '220px',
    },

    '+ .MuiOutlinedInput-root, .MuiOutlinedInput-root': {
      '&.Mui-focused, &:hover': {
        '.MuiOutlinedInput-notchedOutline': {
          borderColor: black['A100'],
          borderWidth: '1px',
        },
      },
    },
  },
}));

export default InputGroup;
