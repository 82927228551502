export default {
  50: 'rgba(0, 0, 0, 0.87)',
  100: 'rgba(0, 0, 0, 0.6)',
  200: 'rgba(0, 0, 0, 0.58)',
  300: 'rgba(0, 0, 0, 0.54)',
  400: 'rgba(0, 0, 0, 0.42)',
  500: 'rgba(0, 0, 0, 0.38)',
  600: 'rgba(0, 0, 0, 0.26)',
  700: 'rgba(0, 0, 0, 0.2)',
  800: 'rgba(0, 0, 0, 0.18)',
  900: 'rgba(0, 0, 0, 0.12)',
  A100: 'rgba(0, 0, 0, 0.1)',
  A200: 'rgba(0, 0, 0, 0.06)',
};
