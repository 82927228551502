import { Button, ChipProps, styled } from '@mui/material';
import { InlineButtonProps } from '@components/InlineButton';
import { StyledComponent } from '@mui/styles';
import { indigo } from '@mui/material/colors';
import { black } from '@src/theme/colors';

export type ChipButtonProps = InlineButtonProps & {
  size?: ChipProps['size'];
};

const ChipButton: StyledComponent<ChipButtonProps> = styled(Button)(({ theme, color, size }) => {
  let backgroundColor: string = indigo[50];
  let padding: string = theme.spacing(0, 1);
  let height: string = theme.spacing(3);

  switch (color) {
    case 'primary':
    case 'warning':
    case 'success':
    case 'secondary':
    case 'error':
    case 'info':
      backgroundColor = theme.palette[color].main;
      break;
  }

  switch (size) {
    case 'medium':
      padding = theme.spacing(0, 1.5); // ???
      height = theme.spacing(3.5); // ???
      break;
    case 'large':
      padding = theme.spacing(0, 2); // ???
      height = theme.spacing(4); // ???
      break;
  }

  return {
    borderRadius: theme.spacing(2),
    textTransform: 'none',
    color: black[50],
    fontSize: 12,
    backgroundColor,
    padding,
    height,
    '&:hover': {
      backgroundColor,
      opacity: 0.8,
    },
  };
});

export default ChipButton;
