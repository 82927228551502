import React from 'react';
import { AppBar as MuiAppBar, AppBarProps as MuiAppBarProps, Box, List, styled, Toolbar } from '@mui/material';
import { drawerWidth } from '@components/Layout/mixins';
import HeaderBarAvatar from '@components/Layout/HeaderBarAvatar';
import { LogoutIcon, ProfileIcon } from '@components/icons';
import HeaderBarMenuItem from '@components/Layout/HeaderBarMenuItem';
import { useGetUserInfoQuery } from '@api/admin_user';
import DropDownPopover from '@components/DropDownPopover';

interface AppBarProps extends MuiAppBarProps {}

const HeaderBar = styled(({ children, ...props }: AppBarProps) => {
  const [popoverAnchorEl, setPopoverAnchorEl] = React.useState<HTMLElement | null>(null);
  const { isLoading, data: userInfo } = useGetUserInfoQuery();

  return (
    <MuiAppBar {...props} component="nav">
      <Toolbar>
        <Box sx={{ width: '100%' }}>{children}</Box>
        {!isLoading && userInfo && (
          <HeaderBarAvatar
            userInfo={userInfo}
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
              setPopoverAnchorEl(event.currentTarget);
            }}
          />
        )}
        <DropDownPopover
          open={!!popoverAnchorEl}
          anchorEl={popoverAnchorEl}
          id={popoverAnchorEl ? 'filter-popover' : undefined}
          onClose={() => setPopoverAnchorEl(null)}
          alignX="right"
        >
          <List sx={{ width: 170 }}>
            <HeaderBarMenuItem to="/profile" icon={<ProfileIcon />} end>
              Профиль
            </HeaderBarMenuItem>
            <HeaderBarMenuItem to="/logout" icon={<LogoutIcon />} end>
              Выйти
            </HeaderBarMenuItem>
          </List>
        </DropDownPopover>
      </Toolbar>
    </MuiAppBar>
  );
})(({ theme }) => ({
  zIndex: theme.zIndex.drawer - 1,
  background: '#FFF',
  boxShadow: '0 1px 1px rgba(0,0,0,.1)',
  marginLeft: drawerWidth,
  width: `calc(100% - ${drawerWidth}px)`,
  '.MuiToolbar-root': {
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
  },
  '.HeaderBarAvatar': {
    position: 'absolute',
    top: theme.spacing(1.5),
    right: theme.spacing(3),
  },
  '.AppSearchForm': {
    [theme.breakpoints.down('lg')]: {
      width: '900px',
    },
  },
}));

export default HeaderBar;
