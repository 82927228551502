import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_HOST } from '@src/config';
import Session from 'supertokens-web-js/recipe/session';

export const transferYoutubeApi = createApi({
  reducerPath: 'transferYoutubeApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_HOST}/admin-api/v1`,
    prepareHeaders: async headers => {
      const accessToken = await Session.getAccessToken();
      if (accessToken) {
        headers.set('Authorization', `Bearer ${accessToken}`);
      }
      return headers;
    },
  }),

  endpoints: builder => ({
    downloadVideo: builder.mutation<
      any,
      {
        youtube_url: string;
        user_id: string;
      }
    >({
      query: ({ youtube_url, user_id }) => ({
        method: 'POST',
        url: 'download',
        body: {
          youtube_url,
          user_id,
        },
      }),
    }),
  }),
});

export const { useDownloadVideoMutation } = transferYoutubeApi;
