import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { userApi } from '@api/user';
import { userContentsApi } from '@api/usercontents';
import { adminUserApi } from '@api/admin_user';
import { commentsApi } from '@api/comments';
import { paymentsApi } from '@api/payments';
import { complaintsApi } from '@api/complaints';
import { transferYoutubeApi } from '@api/transfer_youtube';

export const store = configureStore({
  reducer: {
    [userApi.reducerPath]: userApi.reducer,
    [adminUserApi.reducerPath]: adminUserApi.reducer,
    [userContentsApi.reducerPath]: userContentsApi.reducer,
    [commentsApi.reducerPath]: commentsApi.reducer,
    [paymentsApi.reducerPath]: paymentsApi.reducer,
    [complaintsApi.reducerPath]: complaintsApi.reducer,
    [transferYoutubeApi.reducerPath]: transferYoutubeApi.reducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(
      userApi.middleware,
      adminUserApi.middleware,
      userContentsApi.middleware,
      commentsApi.middleware,
      paymentsApi.middleware,
      complaintsApi.middleware,
      transferYoutubeApi.middleware,
    ),
});

setupListeners(store.dispatch);
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
