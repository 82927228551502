import React from 'react';
import { Box, BoxProps, CircularProgress, styled, Typography } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { red } from '@mui/material/colors';
import { black } from '@src/theme/colors';

type ComplaintChipProps = BoxProps & {
  label: string;
  value?: number;
  loading?: boolean;
};

const ComplaintChip = styled(({ value, label, loading, className, ...props }: ComplaintChipProps) => {
  return (
    <Box {...props} className={className + ' AppComplaintChip'}>
      <ErrorOutlineIcon color="error" />
      <Typography fontSize={16} fontWeight={700} sx={{ ml: 0.5, display: 'flex', alignItems: 'center' }}>
        {loading ? <CircularProgress size={16} /> : typeof value !== 'undefined' ? value : '?'}
      </Typography>
      <Typography fontSize={12} fontWeight={400} sx={{ ml: 0.5 }}>
        {label}
      </Typography>
    </Box>
  );
})(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(1, 1.5, 1, 1),
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${red[100]}`,
  color: black[50],
}));

export default ComplaintChip;
