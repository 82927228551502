import React from 'react';
import CardDrawer from '@components/CardDrawer';
import { UserModel } from '@src/types/models';
import UserCard from '@components/UserCard';

type UserCardDrawerProps = {
  user: UserModel | null;
  onClose?: () => void;
};

const UserCardDrawer = ({ user, onClose }: UserCardDrawerProps) => {
  return (
    <CardDrawer open={!!user} onClose={onClose} id={'UserCardDrawer'}>
      {user && <UserCard user={user} onClose={onClose} />}
    </CardDrawer>
  );
};

export default UserCardDrawer;
