import React, { useEffect, useRef, useState } from 'react';
import { CircularProgress, FormControl, FormControlProps, InputAdornment, InputLabel, OutlinedInput, Popover } from '@mui/material';
import { userApi, UsersQueryDefinition } from '@api/user';
import { useAppDispatch } from '@src/hooks';
import { UserModel } from '@src/types/models';
import { DEFAULT_PER_PAGE } from '@src/config';
import UsersTable from '@components/UsersTable';

type UserAutoCompleteControlProps = Omit<FormControlProps, 'onSelect'> & {
  popoverAnchorEl: HTMLElement;
  onSelect: (user: UserModel) => void;
};

const UserAutoCompleteControl = ({ popoverAnchorEl, onSelect, ...props }: UserAutoCompleteControlProps) => {
  const [inputValue, setInputValue] = useState<string>('');
  const [users, setUsers] = useState<UserModel[]>([]);
  const [page, setPage] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(DEFAULT_PER_PAGE);
  const [orderBy, setOrderBy] = useState<string | undefined>(undefined);
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [popoverOpen, setPopoverOpen] = React.useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const timerRef = useRef<NodeJS.Timeout>();
  const dispatch = useAppDispatch();

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    setTimeout(() => {});
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSortOrder = (property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  useEffect(() => {
    const nickname = inputValue.trim();
    let request: UsersQueryDefinition;

    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    if (nickname.length < 3) {
      return;
    }

    setLoading(true);

    timerRef.current = setTimeout(() => {
      request = dispatch(
        userApi.endpoints.userList.initiate(
          {
            page: page + 1,
            page_size: rowsPerPage,
            order: orderBy && (order === 'asc' ? orderBy : `-${orderBy}`),
            filter: {
              nickname,
            },
          },
          {
            forceRefetch: true,
          },
        ),
      );

      request.finally(() => {
        setLoading(false);
      });

      request.then(({ data }) => {
        if (data) {
          setUsers(data.results);
          setTotalCount(data.pagination.total_count);
        }
      });
    }, 200);

    return () => {
      setLoading(false);

      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }

      if (request) {
        request.abort();
      }
    };
  }, [inputValue, page, rowsPerPage, orderBy, order]);

  useEffect(() => {
    setPopoverOpen(users.length > 0);
  }, [users.length]);

  return (
    <>
      <FormControl {...props}>
        <InputLabel>Поиск по нику</InputLabel>
        <OutlinedInput
          type="text"
          onChange={e => {
            setInputValue(e.target.value);
          }}
          value={inputValue}
          label="Поиск по нику"
          endAdornment={
            !loading ? (
              <></>
            ) : (
              <InputAdornment position="end">
                <CircularProgress size={20} />
              </InputAdornment>
            )
          }
        />
      </FormControl>
      <Popover
        open={popoverOpen}
        anchorEl={popoverAnchorEl}
        onClose={() => setPopoverOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <UsersTable
          onRowsPerPageChange={handleChangeRowsPerPage}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onSort={handleSortOrder}
          onSelect={onSelect}
          count={totalCount}
          orderBy={orderBy}
          order={order}
          users={users}
          page={page}
        />
      </Popover>
    </>
  );
};

export default UserAutoCompleteControl;
